import dayjs from "dayjs";
import { defineStore } from "pinia";

import { api } from "~/api";
import { User } from "~/entities/user";
import { Phone } from "~/shared/phone-number";
import { config } from "~/config";
import { useProfile } from "~/stores/profile.store";
import { LoginByToken, SendCodeParams } from "~/api/dto/phone.dto";
import { NotificationType, useNotification } from "~/stores/notification.store";
import { $t } from "~/i18n";
import { useUser } from "./user.store";
import { Router } from "vue-router";
import { removeWzIntegrationTokenFromLocalStorage } from "~/composables/wz-integration-token-local-storage";

interface State {
  user: User;
  phone: Phone;
  canLogin: boolean;

  isValidPhone: boolean;

  hasError: boolean;
  error: string;

  authCode: string;
  lastSendTime?: Date;
  isNewUser: boolean;
  wzIntegrationToken: string | null;
  isAlreadyWzExistsError: boolean;
  isInited: boolean;
  router: unknown | null;
}

export const useAuth = defineStore("auth", {
  state: (): State => ({
    user: Object.create(null),
    phone: {
      code: "+7",
      number: "",
      phoneNumber: "",
      username: "",
    },
    canLogin: false,

    isValidPhone: true,

    hasError: false,
    error: "",
    authCode: "",
    isNewUser: false,
    wzIntegrationToken: null,
    isAlreadyWzExistsError: false,
    isInited: false,
    router: null as Router | null,
  }),

  actions: {
    setNumber(payload: string) {
      this.phone.number = payload;
    },
    setAuthCode(payload: string) {
      this.authCode = payload;
    },
    setUsername(payload: string) {
      this.phone.username = payload;
    },
    setError(payload: string) {
      this.error = payload;
      this.hasError = true;
    },
    setWzIntegrationToken(payload: string) {
      this.wzIntegrationToken = payload;
    },
    setInit(payload: boolean) {
      this.isInited = payload;
    },
    setRouter(router: Router) {
      this.router = router;
    },
    async checkToken(token: string) {
      const { isExists } = await api.auth.isWzUserExists(token);

      if (!isExists) {
        const { pushNotification } = useNotification();
        pushNotification({
          message: "Не существует пользователя с таким токеном",
          type: NotificationType.error,
        });
      }
    },
    async checkPhone() {
      const { data: payload } = await api.auth.isUserExists({
        phoneNumber: this.phoneNumber,
      });

      try {
        if (!this.handleError(payload)) {
          this.canLogin = payload.isExists;
        }
      } catch (e: any) {
        if (!this.handleError(e.response.data)) {
          this.canLogin = false;
        }
      }
    },
    async checkWzApiKey() {
      try {
        if (!this.wzIntegrationToken) return;
        const result = await api.auth.isUserExistsByWzApi(
          this.wzIntegrationToken,
        );
        this.handleError(result);
      } catch (err: any) {
        this.handleError(err.response.data);
      }
    },
    async sendCode(params: SendCodeParams) {
      try {
        const { data: payload } = this.canLogin
          ? await api.auth.sendLoginCode({
              ...params,
              phoneNumber: this.phoneNumber,
            })
          : await api.auth.sendRegisterCode({
              ...params,
              phoneNumber: this.phoneNumber,
            });

        this.lastSendTime = new Date();
        this.handleError(payload);
      } catch (e: any) {
        console.log("sendCode error in catch: ", e);
        this.handleError(e.response.data);
      }
    },
    async verifyCode() {
      const { authCode } = this;

      try {
        const { data: payload } = this.canLogin
          ? await api.auth.loginVerify({ authCode })
          : await api.auth.registerVerify({ authCode });

        if (!this.handleError(payload)) {
          if (this.canLogin) {
            await this.fetchUser();
          }

          if (!this.canLogin && config.nodeEnv === "production") {
            window.createYmEvent("reachGoal", "Lead");
          }
        }
      } catch (e: any) {
        console.log("verifyCode error in catch: ", e);

        if (!this.handleError(e.response.data)) {
          await this.fetchUser();

          if (!this.canLogin && config.nodeEnv === "production") {
            window.createYmEvent("reachGoal", "Lead");
          }
        }
      }
    },
    async register() {
      try {
        const { data: payload } = await api.auth.register({
          username: this.phone.username,
          phoneNumber: this.phoneNumber,
          token: this.wzIntegrationToken ?? undefined,
          isWzIntegration: !!this.wzIntegrationToken,
        });

        if (!this.handleError(payload)) {
          await this.fetchUser();
          this.isNewUser = true;
          removeWzIntegrationTokenFromLocalStorage(this);
          if (!this.canLogin && config.nodeEnv === "production") {
            window.createYmEvent("reachGoal", "Lead");
          }
        }
      } catch (e: any) {
        console.log("verifyCode error in catch: ", e);

        if (!this.handleError(e.response.data)) {
          await this.fetchUser();

          if (!this.canLogin && config.nodeEnv === "production") {
            window.createYmEvent("reachGoal", "Lead");
          }
        }
      }
    },
    async loginToken(payload: LoginByToken) {
      const response = await api.auth.loginToken(payload);
      if (response?.error) {
        const { pushNotification } = useNotification();
        let message = "";

        if (response.error?.code) {
          message = $t(`errors.${response.error.code}`);
        } else {
          message = response.error?.message;
        }

        pushNotification({ type: NotificationType.error, message });
        return null;
      }

      const { setGuestView } = useUser();
      setGuestView(true);
      await this.fetchUser();

      return response;
    },
    async fetchUser() {
      const { setUser, setGuestView } = useUser();
      const { setConfirmationUrl } = useProfile();
      const { data: payload } = await api.auth.getUser();
      if (!this.handleError(payload)) {
        this.user = payload;

        setUser(payload);
        setConfirmationUrl(payload.subscriptionConfirmationUrl);
        setGuestView(payload.isGuest);
      }

      if (this.handleError(payload)) {
        if (payload.error.statusCode === 401) {
          this.user = Object.create(null);
        }
      }
    },
    toPath(path: string) {
      if (this.router) {
        this.router.push(path);
      }
    },
    async logout() {
      const { data: payload } = await api.auth.logout();

      if (!this.handleError(payload)) {
        this.clearAuthData();
      }
    },
    clearAuthData() {
      this.user = Object.create(null);

      this.phone.code = "+7";
      this.phone.number = "";
      this.phone.phoneNumber = "";
      this.phone.username = "";
      this.authCode = "";
      this.setInit(false);
    },

    setValidPhone(payload: boolean) {
      this.isValidPhone = payload;
    },

    resetError() {
      this.hasError = false;
      this.error = "";
    },

    resetAlreadyWzError() {
      this.isAlreadyWzExistsError = false;
    },

    handleError(payload: any) {
      if (!payload.error) {
        this.resetError();
        return false;
      }
      let errorMsg = "";

      if (payload?.error?.message) {
        if (Array.isArray(payload.error.message)) {
          payload.error.message.forEach((message: string) => {
            if (message.includes("phoneNumber must be a valid")) {
              errorMsg = message;
            }
          });
        } else {
          errorMsg = payload.error.message;
        }
      } else {
        errorMsg = payload.error.code || "";
      }

      this.isAlreadyWzExistsError = payload.error?.statusCode === 409;
      this.hasError = true;
      this.error = errorMsg;

      return true;
    },
    getNextSendSeconds() {
      if (!this.lastSendTime) {
        return 0;
      }

      const nextTime = dayjs(this.lastSendTime).add(1, "minute");
      let seconds = nextTime.diff(dayjs(), "seconds");

      if (seconds < 0) {
        this.lastSendTime = undefined;
        seconds = 0;
      }

      return seconds;
    },
    async checkReCaptcha(token: string) {
      try {
        const { data } = await api.auth.checkReCaptcha(token);
        return data.data;
      } catch (err) {
        return [];
      }
    },
  },

  getters: {
    authorized: (state) => !!state.user.id,
    userId: (state) => state.user.id,
    isValidPhoneLength: (state) =>
      state.phone.number.length > 11 && !state.hasError,
    hasValidPhone: (state: State) => state.isValidPhone,
    phoneNumber: (state) => state.phone.number,
    username: (state) => state.phone.username,
    isAlreadyWzExists: (state) => state.isAlreadyWzExistsError,
    getWzIntegrationToken: (state) => state.wzIntegrationToken,
    getUserPhone: (state) => state.user?.phoneNumber,
  },
});
