<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { onKeyStroke } from "@vueuse/core";
import {
  IconArrowLeftMd as ArrowIcon,
  IconFilledEditMd as EditIcon,
} from "@profeat/ui-kit";

import { useHeader } from "~/stores/header.store";
import { useSidebar } from "~/composables/useSidebar";
const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const headerStore = useHeader();
const { state, setIsOpened } = useSidebar();

const editableTitleInput = ref("");
const editElVisible = ref(true);
const titleInputFocused = ref(false);
const editableTitleEl = ref<HTMLInputElement | null>(null);

const title = computed(() => {
  const pageTitle = headerStore.routeMeta.i18Title;
  if (route.fullPath.includes("trigger-broadcast/create/"))
    return t("triggerBroadcast.sample.header.create");
  else if (route.fullPath.includes("trigger-broadcast/change/"))
    return t("triggerBroadcast.sample.header.edit");
  return pageTitle ? t(pageTitle) : "";
});
const editableTitleError = computed(() => {
  if (
    editableTitleInput.value.length < 3 &&
    headerStore.routeMeta.editableTitle
  )
    return t("chatbots.minimumLengthName");

  return "";
});
const inputStyle = computed(
  () => `width: ${(editableTitleInput.value.length + 1) * 10}px`,
);

const setEditElVisible = (payload: boolean) => (editElVisible.value = payload);
const goBack = () => router.back();
const onEditClick = () => {
  if (editableTitleEl?.value) editableTitleEl.value.focus();
};
const updateTitle = () => {
  titleInputFocused.value = true;

  if (editableTitleError.value.length) {
    setEditElVisible(true);
    return;
  }

  headerStore.updateEditableTitle(editableTitleInput.value);

  setEditElVisible(true);
};
const onEditTitleFocus = () => {
  titleInputFocused.value = true;
  setEditElVisible(false);
};

onKeyStroke("Enter", () => {
  if (titleInputFocused.value) {
    editableTitleEl.value?.blur();
    updateTitle();
  }
});
watch(
  () => headerStore.routeMeta.editableTitle,
  (value) => (editableTitleInput.value = value),
);
</script>

<template>
  <div class="header">
    <div class="header-menu">
      <PButton color="secondary" @click="setIsOpened(!state.isOpened)">
        <IconArrowDoubleRightSm
          :style="{
            color: '#b6c7d6',
          }"
        />
      </PButton>
    </div>
    <div class="header-title">
      <ArrowIcon
        class="arrow-icon"
        v-if="headerStore.routeMeta.withBack"
        @click="goBack"
      />

      <div class="edit-title" v-if="headerStore.routeMeta.editable">
        <div class="input-field">
          <input
            v-model="editableTitleInput"
            :class="{ 'error-outline': editableTitleError }"
            :style="inputStyle"
            :maxlength="30"
            ref="editableTitleEl"
            @focus="onEditTitleFocus"
            @blur="updateTitle"
          />

          <span class="error" v-if="editableTitleError">
            {{ editableTitleError }}
          </span>
        </div>

        <div class="flex items-center">
          <EditIcon
            class="color-[#91A0AE] cursor-pointer"
            v-if="editElVisible"
            @click="onEditClick"
          />
        </div>
      </div>

      <p v-else>{{ title }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  box-sizing: border-box;
  height: 64px;
  padding: 12px 40px;
  background: var(--common-white, #fff);
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &-title {
    display: flex;
    align-items: center;
    gap: 33px;
    flex-grow: 1;

    .arrow-icon {
      cursor: pointer;
    }

    .edit-title {
      display: flex;
      align-items: center;
      gap: 12px;

      .input-field {
        display: flex;
        flex-direction: column;

        input {
          color: var(--typography-titles);
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: -0.18px;
        }
      }

      .error-outline {
        color: var(--additional-error);
      }

      .error {
        color: var(--additional-error);
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
      }
    }

    p {
      color: var(--common-black);
      font-size: 18px;
      font-weight: 600;
      line-height: 155.5%;
      letter-spacing: -0.18px;
    }
  }

  &-menu {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .header {
    &-menu {
      display: block;
      margin-right: 1rem;
    }
  }
}

@media screen and (max-width: $small) {
  .header {
    padding: 0;
    justify-content: center;

    &-title {
      display: none;
    }
  }
}
</style>
