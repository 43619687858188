import { useAuth } from '~/stores/auth.store';
import { useChannels } from '~/stores/channel.store';
import { useContacts } from '~/stores/contacts.store';
import { useTemplates } from '~/stores/template.store';

export const authPaths = [
  '/login',
  '/register',
  '/login/confirm',
  '/register/confirm',
  '/error',
  '/register/after-without-wazzup',
];

/**
 *  Подгружает пользовательские данные для работы приложения.
 *  Выполняется единожды, с условием что пользователь авторизован.
 *
 *  Последовательно загружает:
 *  - Список каналов, с синхронизацией
 *  - Список контактов
 *  - Список шаблонов, с синхронизацией
 */
export const initUser = async () => {
  const authStore = useAuth();

  if (!authStore.isInited && authStore.authorized) {
    const { fetchChannels } = useChannels();
    const { initContacts } = useContacts();
    const { fetchAndSync } = useTemplates();

    await fetchChannels();
    await initContacts();
    await fetchAndSync();

    authStore.setInit(true);
  }
};
