<script setup lang="ts">
import ProfileModalConfirm from '~/components/profileV2/modal/ProfileModalConfirm.vue';
import ProfileModalEditChannel from '~/components/profileV2/modal/ProfileModalEditChannel.vue';
import ProfileModalSwitch from '~/components/profileV2/modal/ProfileModalSwitchChannel.vue';
import ProfileModalDeactivate from '~/components/profileV2/modal/ProfileModalDeactivateChannel.vue';
import ProfileModalLimit from '~/components/profileV2/modal/ProfileModalChannelLimit.vue';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useChannels } from '~/stores/channel.store';
import { NotificationType, useNotification } from '~/stores/notification.store';
import { useProfile } from '~/stores/profile.store';

const CHANNEL_COUNT_LIMIT = 1;

const channelsStore = useChannels();
const { pushNotification } = useNotification();
const { t } = useI18n();
const profileStore = useProfile();

const { getChannels } = storeToRefs(channelsStore);
const modalConfirmation = ref<InstanceType<typeof ProfileModalConfirm>>();
const modalEditChannel = ref<InstanceType<typeof ProfileModalEditChannel>>();
const modalSwitchChannel = ref<InstanceType<typeof ProfileModalSwitch>>();
const modalDeactivateChannel = ref<InstanceType<typeof ProfileModalDeactivate>>();
const modalChannelLimit = ref<InstanceType<typeof ProfileModalLimit>>();

const channelList = computed(() => {
  return getChannels.value;
});

const onDelete = async (channelId: string) => {
  const ok = await modalConfirmation.value?.show({
    title: t('profile.modals.removeChannelConfirm.title'),
    message: t('profile.modals.removeChannelConfirm.description'),
    okBtn: t('profile.modals.removeChannelConfirm.remove'),
  });
  if (ok) {
    const isSuccess = await channelsStore.removeChannelById(channelId);
    if (isSuccess) {
      pushNotification({
        message: t('profile.channel.channelDelete'),
        type: NotificationType.default,
      });
    }
  }
};

const onEdit = async (channelId: string) => {
  const link = await profileStore.fetchEditIFrame(channelId);
  modalEditChannel.value?.show(link);
};

const onSwitch = async (channelId: string) => {
  const channel = getChannels.value.find(({ id }) => id === channelId)!;
  const otherChannels = channelsStore.getChannels.filter(
    ({ id, transport }) => {
      return channel.id !== id && transport == channel.transport;
    },
  );
  if (otherChannels.length === 0) {
    await channelsStore.switchSelectedChannel({
      channelId: channel.id,
      transport: channel.transport,
    });
    return;
  }

  const isSwitch = await modalSwitchChannel.value
    ?.show()
    .then((result) => result)
    .catch((error) => error);

  if (isSwitch) {
    await channelsStore.switchSelectedChannel({
      channelId: channel.id,
      transport: channel.transport,
    });
  }
};

const onDeactivate = async (channelId: string) => {
  const isDeactivate = await modalDeactivateChannel.value
    ?.show()
    .then((result) => result)
    .catch((error) => error);

  if (isDeactivate) {
    await channelsStore.deactivateSelectedChannel({ channelId });
  }
};

const onActivate = async (channelId: string) => {
  const channel = getChannels.value.find(({ id }) => id === channelId)!;
  const transport = getChannels.value.filter(
    ({ transport, isSelected }) =>
      transport === channel.transport && isSelected,
  );

  if (transport.length >= CHANNEL_COUNT_LIMIT) {
    modalChannelLimit.value?.show();
    return;
  }

  await channelsStore.activateSelectedChannel({ channelId });
};
</script>

<template>
  <ProfileModalConfirm ref="modalConfirmation" />
  <ProfileModalEditChannel ref="modalEditChannel" />
  <ProfileModalSwitch ref="modalSwitchChannel" />
  <ProfileModalDeactivate ref="modalDeactivateChannel" />
  <ProfileModalLimit ref="modalChannelLimit" />

  <ul class="profile-channel__list" v-if="channelList.length">
    <ProfileChannelListItem
      v-for="(channel, index) of channelList"
      :key="channel.id"
      :channel="channel"
      :divider="index + 1 !== channelList.length"
      @delete="onDelete"
      @edit="onEdit"
      @switch="onSwitch"
      @activate="onActivate"
      @deactivate="onDeactivate"
    />
  </ul>
</template>

<style scoped></style>
