<script lang="ts" setup>
import { computed, onMounted, watch } from "vue";
import { useBreakpoints } from "@vueuse/core";
import { useRouter, useRoute } from "vue-router";
import { formatIncompletePhoneNumber } from "libphonenumber-js";
import {
  IconProfileMd as ProfileIcon,
  IconRefreshMd as RefreshIcon,
  IconMailMd as BroadcastIcon,
  IconPeopleMd as ContactsIcon,
  IconEnterMd,
  IconInfoSm,
  IconHeartHandshakeSm,
  IconBotSm,
  PTag,
} from "@profeat/ui-kit";

import { useUser } from "~/stores/user.store";
import { useChannels } from "~/stores/channel.store";
import { useAuth } from "~/stores/auth.store";
import { useCompany } from "~/stores/company.store";
import { useI18n } from "vue-i18n";
import { useSidebar } from "~/composables/useSidebar";

const router = useRouter();
const route = useRoute();
const { collapse, isVisible } = useBreakpoints({
  collapse: 1500,
  isVisible: 1024,
});
const userStore = useUser();
const channelsStore = useChannels();
const { logout, getUserPhone } = useAuth();
const { t } = useI18n();
const { state, setSelected, setIsOpened } = useSidebar();

enum SidebarItemsList {
  "profile",
  "contacts",
  "templates",
  "broadcast",
  "trigger-broadcast",
  "chats",
  "chat-bots",
}

const RouteNames: Record<string, string> = {
  profile: "profile",
  broadcastSender: "broadcast",
  contactsUpload: "contacts",
};

type SidebarItemsListStrings = keyof typeof SidebarItemsList;

const phoneNumber = computed(() =>
  formatIncompletePhoneNumber(`+${userStore.user.phoneNumber}`),
);

const hasProfileAlert = computed(() => {
  const noChannels = channelsStore.getChannels.length < 1;
  const alerts = [noChannels];

  return alerts.some(Boolean);
});

const SUPPORT_TG_URL = "https://t.me/care_sendbot";
const { getCompanyId } = useCompany();
const supportTgLink = computed(
  () => `${SUPPORT_TG_URL}?start=${getUserPhone}_${getCompanyId}`,
);

const exit = async () => {
  await logout();
  await router.push("/login");
};

const onClickPro = async () => {
  await router.push('/tariffs');
};

onMounted(() => {
  const currentItem: SidebarItemsListStrings = route.path.split(
    "/",
  )[1] as SidebarItemsListStrings;

  if (
    currentItem !== undefined &&
    SidebarItemsList[currentItem] !== undefined
  ) {
    setSelected(currentItem);
  }
});

watch(collapse, () => {
  setIsOpened(collapse.value);
});

watch(isVisible, () => {
  const value = isVisible.value && collapse.value;
  setIsOpened(value);
});
watch(
  () => router.currentRoute.value,
  (value) => {
    const routeNameMatch = RouteNames[value.name as string];
    if (routeNameMatch) {
      setSelected(routeNameMatch);
    }
  },
);

const clickEvent = (e: any) => {
  if (e.value !== "logout" || e.value !== "link") {
    setSelected(e.value);
  }
  if (e.value === "logout") {
    exit();
  }
};
</script>

<template>
  <div class="sidebar">
    <PSideBar
      v-model:selected="state.selected"
      v-model:opened="state.isOpened"
      :items="{
        menu: [
          {
            value: 'profile',
            icon: ProfileIcon,
            text: t('menu.profile'),
            indicatorColor: hasProfileAlert && 'error',
            to: '/profile',
          },
          {
            value: 'contacts',
            icon: ContactsIcon,
            text: t('menu.customers'),
            to: '/contacts',
          },
          'divider',
          {
            value: 'broadcast',
            icon: BroadcastIcon,
            text: t('menu.mass-mailing'),
            to: '/broadcast',
          },
          {
            value: 'trigger-broadcast',
            icon: RefreshIcon,
            text: t('menu.trigger-broadcast'),
            to: '/trigger-broadcast',
          },
          'divider',
          {
            value: 'chat-bots',
            icon: IconBotSm,
            text: t('menu.chat-bots'),
            templateBy: 'tag',
            to: '/chat-bots',
          },
        ],
        bottom: [
          {
            value: 'link',
            icon: IconHeartHandshakeSm,
            href: supportTgLink,
            text: t('menu.help'),
            target: '_blank',
          },
          {
            value: 'link',
            icon: IconInfoSm,
            href: 'https://sendbot.yourgood.ru/help',
            text: t('menu.knowledge-db'),
            target: '_blank',
          },
          {
            value: 'logout',
            icon: IconEnterMd,
            text: t('auth.logout'),
          },
        ],
      }"
      :title="phoneNumber"
      :padding="'medium'"
      :product="'sendbot'"
      @click:item="clickEvent($event)"
    >
      <template #right-side-item="{ item }">
        <PTag
          v-if="item.description"
          color="blue"
          class="font-weight-regular text-14px"
        >
          {{ item.description }}
        </PTag>
      </template>
    </PSideBar>
  </div>
</template>

<style lang="scss" scoped>
.sidebar {
  border-right: 1px solid #2b323814;

  :deep(.item-wrapper) {
    align-items: baseline;
  }
}
</style>
